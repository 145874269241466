import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Create a Connect AirPods CLI Command",
  "author": "tzookb",
  "type": "post",
  "date": "2019-07-23T13:36:03.000Z",
  "url": "/2019/07/create-a-connect-airpods-cli-command/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["How To"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`hello`}</h2>
    <ul>
      <li parentName="ul">{`sdsa`}</li>
      <li parentName="ul">{`dsad`}</li>
    </ul>
    <p>{`Mac and Airpods users, You probably enjoy your super cool airpods on your mac/iphone all the time.`}</p>
    <p>{`But the most annoying thing you need to do is manually clicking the "connect button" whenever you switch devices.`}</p>
    <p>{`That's a pain in the rear. I literally hate using my mouse / trackpad / not my keyboard.`}</p>
    <p>{`So I decided to create a quick alias for the CLI so I can easily type "air" and get my airpods connected.`}</p>
    <p>{`So the steps:`}</p>
    <ul>
      <li parentName="ul">{`install this great `}<a href="https://github.com/lapfelix/BluetoothConnector" target="_blank" rel="noreferrer noopener" aria-label="package (opens in a new tab)">{`package`}</a>{` with homebrew `}<inlineCode parentName="li">{`brew install bluetoothconnector`}</inlineCode></li>
      <li parentName="ul">{`type `}<inlineCode parentName="li">{`bluetoothconnector`}</inlineCode>{` in the cli`}</li>
      <li parentName="ul">{`copy your airpords mac address`}</li>
      <li parentName="ul">{`run this command to connect your airpods: `}<inlineCode parentName="li">{`bluetoothconnector -c xx-xx-xx-xx-xx-xx -n`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`xx-xx-xx-xx-xx-xx`}</inlineCode>{` is your airpods mac address`}</li>
      <li parentName="ul">{`you should get connected by now`}</li>
      <li parentName="ul">{`now lets create the shortcut 🙂`}</li>
      <li parentName="ul">{`add this line to your `}<inlineCode parentName="li">{`.bash_profile`}</inlineCode>{` file
==> `}<inlineCode parentName="li">{`alias air="bluetoothconnector -c xx-xx-xx-xx-xx-xx -n"`}</inlineCode></li>
      <li parentName="ul">{`now you should have the quick shortcut to connect your airpods `}<inlineCode parentName="li">{`air`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      